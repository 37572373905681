<template>
  <div>
    <div class="content_block">
      <query-form label-width="100px" :span="8">
        <template slot="action">
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button type="primary" size="mini" @click="exportExcel" style="margin-bottom: 8px">导出</el-button>
        </template>
        <el-form-item label="工单类型" prop="type">
          <el-select v-model="query.type" placeholder="请选择工单类型" @change="handleSearch">
            <el-option label="全部" :value="null"></el-option>
            <el-option v-for="mod in OrderTypeList" :key="mod.code" :label="mod.name" :value="mod.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工单状态" prop="workOrderStatus">
          <el-select v-model="query.workOrderStatus" placeholder="请选择工单状态" @change="handleSearch">
            <el-option
              v-for="mod in OrderStatusEnumList"
              :key="mod.value"
              :label="mod.name"
              :value="mod.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间" prop="status">
          <el-date-picker
            v-model="query.dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
      </query-form>
    </div>
    <table-with-pagination
      :tableData="tableData"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="createTimeSortInfo"
      @current-change="getTableList"
      @sort-change="getTableList"
    />
  </div>
</template>
<script>
import OrderApi from '@/api/order';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import OrderStatusEnum, { OrderStatusEnumList } from '@/enums/OrderStatusEnum';
import { wrapWithAll } from '@/utils/Enum';
import dayjs from 'dayjs';

export default {
  name: 'EMPLOYEE_WORK_ORDER_TB',
  mixins: [TablePaginationMixin],
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      OrderTypeList: [],
      OrderStatusEnumList: wrapWithAll(OrderStatusEnumList),
      columnList: [
        {
          tooltip: true,
          label: '工单编号',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '工单类型',
          prop: 'typeMsg.name',
        },
        {
          tooltip: true,
          label: '工单状态',
          prop: 'status',
          render: (h, { row }) => {
            if (row.evalStatus === OrderStatusEnum.待评价 || row.evalStatus === OrderStatusEnum.已评价) {
              return <span> {OrderStatusEnum[row.evalStatus]}</span>;
            }
            return OrderStatusEnum[row.status];
          },
        },
        {
          tooltip: true,
          label: '客户名称',
          prop: 'customerMsg.name',
        },
        {
          tooltip: true,
          label: '设备名称',
          prop: 'deviceMsg.name',
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '150px',
          prop: 'createTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '接单时间',
          minWidth: '150px',
          prop: 'orderTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '完成时间',
          minWidth: '150px',
          prop: 'closeTime',
          format: this.$options.filters['date-format'],
        },
      ],
      query: {
        dateRange: ['', ''],
        type: null,
        workOrderStatus: null,
      },
      tableData: [],
    };
  },
  methods: {
    download ({ data, type, fileTitle }) {
      let blob = new Blob([data], { type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileTitle;
      document.querySelectorAll('body')[0].appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    exportExcel () {
      const param = {
        employeeId: this.id,
        type: this.query.type,
        creatStartDate: this.query.dateRange[0],
        creatEndDate: this.query.dateRange[1],
      };

      if (['BE_EVALUATED', 'EVALUATED'].includes(this.query.workOrderStatus)) {
        param.workOrderEvalStatus = this.query.workOrderStatus;
      } else {
        param.workOrderStatus = this.query.workOrderStatus;
      }

      OrderApi.exportWorkOrderExcel({
        employeeId: this.id,
        type: this.query.type,
        workOrderStatus: this.query.workOrderStatus,
        creatStartDate: this.query.dateRange[0],
        creatEndDate: this.query.dateRange[1],
      }).then(({data}) => {
        this.download({
          data: data,
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileTitle: `工单_${dayjs(Date.now()).format('YYYYMMDDHHmmss')}`,
        });
      });
    },
    getFormCategoryList () {
      OrderApi.getFormTypeListByConfigId({ customizeSystemConfigCode: 'WORK_ORDER' }).then(rs => {
        this.OrderTypeList = rs.body?.map(i => ({
          ...i,
          templateId: i.id,
        }));
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    getTableList () {
      const param = {
        principalId: this.id,
        type: this.query.type,
        createStartTime: this.query.dateRange[0],
        createEndTime: this.query.dateRange[1],
        ...this.createTimeSortInfo,
        ...this.pagination,
        pageNum: this.pagination.currentPage,
      };

      if (['BE_EVALUATED', 'EVALUATED'].includes(this.query.workOrderStatus)) {
        param.workOrderEvalStatus = this.query.workOrderStatus;
      } else {
        param.workOrderStatus = this.query.workOrderStatus;
      }

      OrderApi.getProductList(param).then(res => {
        this.tableData = res.body?.records || [];
        this.pagination.total = res.body?.total || 0;
      });
    },
  },
  created () {
    this.getFormCategoryList();
  },
};
</script>
